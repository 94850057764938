///<reference path="./../_reference.ts"/>
module Apex.PosManager.Interfaces {
    export interface IBookPosByDate {
        BranchId: number,
        VgSale: number,
        VgSaleZ: number,
        BVgSale: number,
        VgReturn: number,
        VgReturnZ: number,
        BVgReturn: number,
        BranchName: string
    }
}