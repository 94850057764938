/// <reference path='../_reference.ts' />
module Apex.PosManager.Controllers {
    export enum SelectionType {
        selectAll,
        unselectAll,
        invert
    }
    export interface IManagePosUsersScope extends angular.IScope {
        showRoles: boolean;
        isChanged: boolean;
        datasources: {
            usersDatasource: Array<IPosUserSelectable>,
            rolesDatasource: Array<IPosRoleSelectable>
        }
        actionsTabActive: boolean;
        activeUser: IPosUserSelectable;
        activeRole: IPosRoleSelectable;
        events: {
            refresh: Function;
            save: Function;
            cancel: Function;
            addUser: Function;
            editUser: Function;
            deleteUser: Function;
            changeUserPassword: Function;
            addRole: Function;
            editRole: Function;
            deleteRole: Function;
            selectAll: Function;
            unselectAll: Function;
            invert: Function;
        };
        tabEvents: { tabindexchanged: Function };
        components: {
            usersMainGrid: Components.IApxGrid;
            rolesMainGrid: Components.IApxGrid;
            usersGrid: Components.IApxGrid;
            rolesGrid: Components.IApxGrid;
            actionsGrid: Components.IApxGrid;
        }
    }

    export interface IPosRoleSelectable extends Apex.PosManager.Interfaces.IPosRole {
        isSelected: boolean;
    }
    export interface IPosUserSelectable extends Apex.PosManager.Interfaces.IPosUser {
        isSelected?: boolean;
    }

    export class ManagePosUsers extends Core.Controller {

        static $inject = [Globals.scope, Globals.toaster, Globals.translate, 'posService'];

        constructor(private $scope: IManagePosUsersScope, private toaster, private $translate, private posService: Apex.PosManager.Services.Pos) {
            super(arguments, ManagePosUsers.$inject);
            $scope.showRoles = true;
            $scope.isChanged = $scope.actionsTabActive = false;
            $scope.datasources = {
                rolesDatasource: [], usersDatasource: []
            };
            $scope.tabEvents =
                {
                    tabindexchanged: this.tabIndexChanged
                };

            $scope.events = {
                refresh: this.refresh,
                cancel: this.cancel,
                save: this.save,
                addUser: this.addUser,
                editUser: this.editUser,
                deleteUser: this.deleteUser,
                changeUserPassword: this.changeUserPassword,
                addRole: this.addRole,
                editRole: this.editRole,
                deleteRole: this.deleteRole,
                selectAll: this.selectAll,
                unselectAll: this.unselectAll,
                invert: this.invert
            };

            $scope.components = {
                rolesMainGrid: this.initRolesMainGrid(),
                usersMainGrid: this.initUsersMainGrid(),
                rolesGrid: this.initRolesGrid(),
                usersGrid: this.initUsersGrid(),
                actionsGrid: this.initActionsGrid()
            };


            posService.GetPosUsers().then(res => {
                $scope.components.usersMainGrid.dataSource.data(res.Result);
                _.forEach(res.Result, (item: IPosUserSelectable) => { item.isSelected = false; });
                $scope.datasources.usersDatasource = res.Result;
            });

            posService.GetPosActions().then(res => {
                $scope.components.actionsGrid.dataSource.data(res.Result);
            });

            posService.GetPosRoles().then(res => {
                $scope.components.rolesMainGrid.dataSource.data(res.Result);
                _.forEach(res.Result, (item: IPosRoleSelectable) => { item.isSelected = false; });
                $scope.datasources.rolesDatasource = res.Result;
            });
        }


        deleteUser = () => {
            var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            r.result.then(() => {
                var user: any = this.$scope.components.usersMainGrid.grid.dataItem(this.$scope.components.usersMainGrid.grid.select());

                this.posService.DeletePosUser(user.UserId).then(res => {
                    if (res.success != false) {
                        this.posService.GetPosUsers().then(res => {
                            this.toaster.pop('success', this.$translate.instant('Attention'), this.$translate.instant('successSaved'));
                            this.$scope.components.usersMainGrid.dataSource.data(res.Result);
                            _.forEach(res.Result, (item: IPosUserSelectable) => { item.isSelected = false; });
                            this.$scope.datasources.usersDatasource = res.Result;
                            this.$scope.components.rolesMainGrid.grid.clearSelection();                        
                            this.$scope.components.rolesGrid.dataSource.data([]);
                            delete this.$scope.activeRole
                            delete this.$scope.activeUser;
                        });
                    }
                });
            });
        };


        deleteRole = () => {
            var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            r.result.then(() => {
                var role: any = this.$scope.components.rolesMainGrid.grid.dataItem(this.$scope.components.rolesMainGrid.grid.select());

                this.posService.DeletePosRole(role.RoleId).then(res => {
                    if (res.success != false) {
                        this.posService.GetPosRoles().then(res => {
                            this.toaster.pop('success', this.$translate.instant('Attention'), this.$translate.instant('successSaved'));
                            this.$scope.components.rolesMainGrid.dataSource.data(res.Result);
                            _.forEach(res.Result, (item: IPosRoleSelectable) => { item.isSelected = false; });
                            this.$scope.datasources.rolesDatasource = res.Result;
                            this.$scope.components.usersMainGrid.grid.clearSelection();                       
                            this.$scope.components.usersGrid.dataSource.data([]);
                            delete this.$scope.activeRole;
                            delete this.$scope.activeUser;                           
                        });
                    }
                });
            });
        };

        invert = () => {
            this.selectUnselectFunctionality(SelectionType.invert);
        }

        selectAll = () => {
            this.selectUnselectFunctionality(SelectionType.selectAll);
        };

        unselectAll = () => {
            this.selectUnselectFunctionality(SelectionType.unselectAll);
        };

        selectUnselectFunctionality = (type: SelectionType) => {

            if (this.$scope.isChanged != true) {
                this.$scope.isChanged = true;
                if (this.$scope.showRoles == true) {
                    this.$scope.components.usersMainGrid.gridView.showLoading();
                }
                else {
                    this.$scope.components.rolesMainGrid.gridView.showLoading();
                }
            }
            var select;
            switch (type) {
                case SelectionType.selectAll:
                    select = true;
                    break;
                case SelectionType.unselectAll:
                    select = false;
                    break;
                case SelectionType.invert:
                    select = null;
                    break;
            }

            if (this.$scope.showRoles == true && this.$scope.activeUser != undefined) {
                this.$scope.components.rolesGrid.gridView.showLoading();
                _.forEach(this.$scope.components.rolesGrid.grid.dataSource.data(), (role: IPosRoleSelectable) => {
                    if (select != null)
                        role.isSelected = select;
                    else role.isSelected = !role.isSelected;
                });
                this.$scope.components.rolesGrid.grid.dataSource.sync();
                this.$scope.components.rolesGrid.gridView.hideLoading();
                return;
            }

            if (!this.$scope.showRoles && this.$scope.activeRole != undefined) {
                if (this.$scope.actionsTabActive) {
                    this.$scope.components.actionsGrid.gridView.showLoading();
                    _.forEach(this.$scope.components.actionsGrid.grid.dataSource.data(), (action: any) => {
                        if (select != null)
                            action.isSelected = select;
                        else action.isSelected = !action.isSelected;
                    });
                    this.$scope.components.actionsGrid.grid.dataSource.sync();
                    this.$scope.components.actionsGrid.gridView.hideLoading();
                    return;
                }
                else {
                    this.$scope.components.usersGrid.gridView.showLoading();
                    _.forEach(this.$scope.components.usersGrid.grid.dataSource.data(), (user: IPosUserSelectable) => {
                        if (select != null)
                            user.isSelected = select;
                        else user.isSelected = !user.isSelected;
                    });
                    this.$scope.components.usersGrid.grid.dataSource.sync();
                    this.$scope.components.usersGrid.gridView.hideLoading();
                    return;
                }
            }
        }


        tabIndexChanged = (index: any) => {
            if (this.$scope.isChanged == false) {
                if (index == 0) {
                    this.$scope.showRoles = true;
                    return;
                }
                if (index == 1) {
                    this.$scope.showRoles = false;
                    return;
                }
            }
            if (index == 2) {
                this.$scope.actionsTabActive = false;
                return;
            }
            if (index == 3) {
                this.$scope.actionsTabActive = true;
                return;
            }
        };

        refresh = () => {
            if (this.$scope.showRoles == true) {
                this.refreshFromUsers();
            }
            else {
                this.refreshFromRoles();
            }
        };

        refreshFromUsers = () => {
            this.$scope.components.usersMainGrid.gridView.showLoading();
            this.posService.GetPosUsers().then(res => {
                this.$scope.components.usersMainGrid.dataSource.data(res.Result);
                _.forEach(res.Result, (item: IPosUserSelectable) => { item.isSelected = false; });

                this.$scope.datasources.usersDatasource = res.Result;

                var user = this.$scope.activeUser;
                if (user != undefined && user != null) {
                    this.$scope.components.rolesGrid.dataSource.data(this.$scope.datasources.rolesDatasource);
                    this.$scope.components.rolesGrid.gridView.showLoading();
                    this.posService.GetPosUserRolesByUser(user.UserId).then(res => {
                        _.forEach(this.$scope.components.rolesGrid.dataSource.data(), (role: IPosRoleSelectable) => {
                            role.isSelected = false;
                            for (var j = 0; j < res.Result.length; j++) {
                                var userRole: any = res.Result[j];
                                if (userRole.UserID == user.UserId && userRole.RoleID == role.RoleId) {
                                    Core.Utils.Helper.safeApply(this.$scope, () => { role.isSelected = true });
                                    break;
                                }
                            }
                        });
                        this.$scope.components.rolesGrid.grid.dataSource.sync();
                        this.$scope.components.rolesGrid.gridView.hideLoading();
                    });
                }
                this.$scope.components.usersMainGrid.gridView.hideLoading();
            });
        };

        refreshFromRoles = () => {
            this.$scope.components.rolesMainGrid.gridView.showLoading();
            this.posService.GetPosRoles().then(res => {
                this.$scope.components.rolesMainGrid.dataSource.data(res.Result);
                _.forEach(res.Result, (item: IPosRoleSelectable) => { item.isSelected = false; });

                this.$scope.datasources.rolesDatasource = res.Result;

                var role = this.$scope.activeRole;
                if (role != undefined && role != null) {

                    this.$scope.components.usersGrid.dataSource.data(this.$scope.datasources.usersDatasource);

                    this.$scope.components.usersGrid.gridView.showLoading();
                    this.posService.GetPosUserRolesByRole(role.RoleId).then(res => {
                        _.forEach(this.$scope.components.usersGrid.dataSource.data(), (user: IPosUserSelectable) => {
                            user.isSelected = false;
                            for (var j = 0; j < res.Result.length; j++) {
                                var userRole: any = res.Result[j];
                                if (userRole.UserID == user.UserId && userRole.RoleID == role.RoleId) {
                                    Core.Utils.Helper.safeApply(this.$scope, () => { user.isSelected = true });
                                    break;
                                }
                            }
                        });
                        this.$scope.components.usersGrid.grid.dataSource.sync();
                        this.$scope.components.usersGrid.gridView.hideLoading();
                    });

                    this.$scope.components.actionsGrid.gridView.showLoading();
                    this.posService.GetPosRoleActions(role.RoleId).then(res => {
                        _.forEach(res.Result, (itm: any) => {
                            itm.isSelected = itm.IsGranted;
                        })
                        this.$scope.components.actionsGrid.dataSource.data(res.Result);
                        this.$scope.components.actionsGrid.gridView.hideLoading();
                    });
                }

                this.$scope.components.rolesMainGrid.gridView.hideLoading();
            });
        };

        cancel = () => {
            this.$scope.isChanged = false;
            if (this.$scope.showRoles == true) {
                this.usersRowChangeEvent();
                this.$scope.components.usersMainGrid.gridView.hideLoading();
            }
            else {
                this.rolesRowChangeEvent();
                this.$scope.components.rolesMainGrid.gridView.hideLoading();
            }
        };

        save = () => {
            if (this.$scope.showRoles == true) {
                var user: any = this.$scope.components.usersMainGrid.grid.dataItem(this.$scope.components.usersMainGrid.grid.select());
                var roles = this.$scope.components.rolesGrid.grid.dataSource.data().filter(function (item: IPosRoleSelectable) {
                    return item.isSelected == true;
                }).map(function (item: IPosRoleSelectable) { return item.RoleId });

                this.posService.SavePosUserRoles(user.UserId, null, roles, null).then(res => {
                    if (res.success != false) {
                        this.toaster.pop('success', this.$translate.instant('Attention'), this.$translate.instant('successSaved'));
                        this.$scope.components.usersMainGrid.gridView.hideLoading();
                        this.$scope.isChanged = false;
                    }
                });
            }
            else {
                var role: any = this.$scope.components.rolesMainGrid.grid.dataItem(this.$scope.components.rolesMainGrid.grid.select());

                if (this.$scope.actionsTabActive) {
                    var actions = this.$scope.components.actionsGrid.grid.dataSource.data().filter(function (action: any) { return action.isSelected == true })
                        .map(function (action: any) { return action.ActionID });

                    this.posService.SavePosActions(role.RoleId, actions).then(res => {
                        if (res.success != false) {
                            var resultTranslatedText = this.$translate.instant('successSaved');
                            var attentionTranslatedString = this.$translate.instant('Attention');
                            this.toaster.pop('success', attentionTranslatedString, resultTranslatedText);
                            this.$scope.components.rolesMainGrid.gridView.hideLoading();
                            this.$scope.isChanged = false;
                        }
                    });
                }
                else {
                    var users = this.$scope.components.usersGrid.grid.dataSource.data().filter(function (item: IPosUserSelectable) {
                        return item.isSelected == true;
                    }).map(function (item: IPosUserSelectable) { return item.UserId });

                    this.posService.SavePosUserRoles(null, role.RoleId, null, users).then(res => {
                        if (res.success != false) {
                            var resultTranslatedText = this.$translate.instant('successSaved');
                            var attentionTranslatedString = this.$translate.instant('Attention');
                            this.toaster.pop('success', attentionTranslatedString, resultTranslatedText);
                            this.$scope.components.rolesMainGrid.gridView.hideLoading();
                            this.$scope.isChanged = false;
                        }
                    });
                }

            }
        };

        initUsersMainGrid = (): Components.IApxGrid => {
            var grid: Components.IApxGrid =
                {
                    gridEvents:
                    [{
                        eType: Apex.Components.EventType.Change, eFunction: this.usersRowChangeEvent
                    },
                    {
                        eType: Components.EventType.DataBoud, eFunction: () => {
                            this.$scope.activeUser = this.$scope.components.usersMainGrid.selectedObject;
                        }
                    }
                    ],
                    columns: [
                        {
                            field: "Spend", width: 85, filterable: 'boolean', titleTranslated: "Spend",
                            template: '<div class="text-center" ng-if="dataItem.Spend"><span class="fa fa-check"></span></div>',
                            footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.Spend ? data.Spend.count : 0) #'
                        },
                        { field: 'UserId', width: 40, title: 'Id' },
                        { field: 'UserName', titleTranslated: 'cuser' },
                        { field: 'FirstName', titleTranslated: 'name' },
                        { field: 'LastName', titleTranslated: 'lastname' },
                        { field: 'CardID', titleTranslated: 'card' },
                        { field: 'Email', titleTranslated: 'email' },
                        { field: 'Note', titleTranslated: 'note' }
                    ],
                    settings: { editble: { disableEditRow: true } },
                    dataSource: new kendo.data.DataSource({
                        aggregate: [{ field: "Spend", aggregate: "count" }],
                        data: [],
                        schema:
                        {
                            model: {
                                id: 'UserId',
                                fields: {
                                    Spend: { type: 'boolean' },
                                    UserId: { type: 'number' },
                                    UserName: { type: 'string' },
                                    FirstName: { type: 'string' },
                                    LastName: { type: 'string' },
                                    CardID: { type: 'string' },
                                    Email: { type: 'string' },
                                    Note: { type: 'string' }
                                }
                            }
                        }
                    })
                };
            return grid;
        };


        usersRowChangeEvent = () => {
            var user: any = this.$scope.components.usersMainGrid.grid.dataItem(this.$scope.components.usersMainGrid.grid.select());
            if (user != null) {
                this.$scope.activeUser = user;

                this.$scope.components.rolesGrid.dataSource.data(this.$scope.datasources.rolesDatasource);

                this.$scope.components.rolesGrid.gridView.showLoading();
                this.posService.GetPosUserRolesByUser(user.UserId).then(res => {
                    _.forEach(this.$scope.components.rolesGrid.dataSource.data(), (role: IPosRoleSelectable) => {
                        role.isSelected = false;
                        for (var j = 0; j < res.Result.length; j++) {
                            var userRole: any = res.Result[j];
                            if (userRole.UserID == user.UserId && userRole.RoleID == role.RoleId) {
                                Core.Utils.Helper.safeApply(this.$scope, () => { role.isSelected = true });
                                break;
                            }
                        }
                    });
                    this.$scope.components.rolesGrid.grid.dataSource.sync();
                    this.$scope.components.rolesGrid.gridView.hideLoading();
                });
            }
            else {
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    delete this.$scope.activeUser;
                    this.$scope.components.rolesGrid.grid.dataSource.data([]);
                });

            }
        };

        rolesRowChangeEvent = () => {
            var role: any = this.$scope.components.rolesMainGrid.grid.dataItem(this.$scope.components.rolesMainGrid.grid.select());
            if (role != null) {
                this.$scope.activeRole = role;

                this.$scope.components.usersGrid.dataSource.data(this.$scope.datasources.usersDatasource);

                this.$scope.components.usersGrid.gridView.showLoading();
                this.posService.GetPosUserRolesByRole(role.RoleId).then(res => {
                    _.forEach(this.$scope.components.usersGrid.dataSource.data(), (user: IPosUserSelectable) => {
                        user.isSelected = false;
                        for (var j = 0; j < res.Result.length; j++) {
                            var userRole: any = res.Result[j];
                            if (userRole.UserID == user.UserId && userRole.RoleID == role.RoleId) {
                                Core.Utils.Helper.safeApply(this.$scope, () => { user.isSelected = true });
                                break;
                            }
                        }
                    });
                    this.$scope.components.usersGrid.grid.dataSource.sync();
                    this.$scope.components.usersGrid.gridView.hideLoading();
                });

                this.$scope.components.actionsGrid.gridView.showLoading();

                this.posService.GetPosRoleActions(role.RoleId).then(res => {
                    _.forEach(res.Result, (itm: any) => {
                        itm.isSelected = itm.IsGranted;
                    })
                    this.$scope.components.actionsGrid.dataSource.data(res.Result);
                    this.$scope.components.actionsGrid.gridView.hideLoading();
                });
            }
            else {
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    delete this.$scope.activeRole;
                    this.$scope.components.actionsGrid.grid.dataSource.data([]);
                    this.$scope.components.usersGrid.grid.dataSource.data([]);
                });
            }
        };


        initRolesMainGrid = (): Components.IApxGrid => {
            var grid: Components.IApxGrid = {
                gridEvents: [{
                    eType: Apex.Components.EventType.Change, eFunction: this.rolesRowChangeEvent
                },
                {
                    eType: Components.EventType.DataBoud, eFunction: () => {
                        this.$scope.activeRole = this.$scope.components.rolesMainGrid.selectedObject;
                    }
                }
                ],
                settings: { editble: { disableEditRow: true } },
                dataSource: new kendo.data.DataSource({
                    data: [], schema: {
                        model: {
                            id: 'RoleId', fields: {
                                Name: { type: 'string' },
                                Namee: { type: 'string' },
                            }
                        }
                    }
                }),
                columns: [{ field: 'Name', titleTranslated: 'caption' }, { field: 'Namee', titleTranslated: 'nameCaptionEn' }],
            };
            return grid;
        };

        initRolesGrid = (): Components.IApxGrid => {
            var grid: Components.IApxGrid = {
                settings: { editble: { disableEditRow: true } },
                dataSource: new kendo.data.DataSource({
                    data: [],
                    aggregate: [{ field: "Name", aggregate: "count" }],
                    schema: {
                        model: {
                            id: 'RoleId',
                            fields: {
                                isSelected: { type: 'boolean' },
                                Name: { type: 'string' },
                                Namee: { type: 'string' },
                            }
                        }
                    }
                }),
                gridEvents: [{
                    eType: Components.EventType.ISelectedChange, customFunction: (e) => {
                        Apex.PosManager.Controllers.ManagePosUsers.checkedChanged()
                    }
                }],
                columns: [{
                    field: 'isSelected', width: 85, filterable: 'boolean', titleTranslated: 'access',
                    template: '<input type="checkbox" style="margin-left: 40%;" ng-model="dataItem.isSelected" onclick="Apex.PosManager.Controllers.ManagePosUsers.checkedChanged()" />'
                },
                {
                    field: 'Name',
                    footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.Name ? data.Name.count : 0) #',
                    titleTranslated: 'caption'
                },
                { field: 'Namee', titleTranslated: 'nameCaptionEn' }]
            };
            return grid;
        };


        public static checkedChanged = () => {
            var scope: IManagePosUsersScope = <IManagePosUsersScope>angular.element($("#pageBody")).scope();
            if (scope.isChanged != true) {
                scope.isChanged = true;
                if (scope.showRoles == true) {
                    scope.components.usersMainGrid.gridView.showLoading();
                }
                else {
                    scope.components.rolesMainGrid.gridView.showLoading();
                }
            }
        };

        initUsersGrid = (): Components.IApxGrid => {
            var grid: Components.IApxGrid = {
                columns: [
                    {
                        field: 'isSelected', width: 85, filterable: 'boolean', titleTranslated: 'access',
                        template: '<input type="checkbox" style="margin-left: 40%;" ng-model="dataItem.isSelected" onclick="Apex.PosManager.Controllers.ManagePosUsers.checkedChanged()" />'
                    },
                    {
                        field: "Spend", width: 90, filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: true, text: this.$translate.instant('Spend') },
                                    { value: false, text: this.$translate.instant('active') }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"
                            })
                        },
                        titleTranslated: "Spend", template: `<div class="text-center" ng-if="dataItem.Spend"><i class="fa fa-circle text-danger" style="font-size: 8px;"></i></div>
                          <div class="text-center"  ng-if="!dataItem.Spend"><i class="fa fa-circle text-success" style="font-size: 8px;"></i></div>` },
                    { field: 'UserId', width: 40, title: 'Id' },
                    { field: 'UserName', titleTranslated: 'cuser' },
                    { field: 'FirstName', titleTranslated: 'name' },
                    { field: 'LastName', titleTranslated: 'lastname' },
                    { field: 'CardID', titleTranslated: 'card' },
                    { field: 'Email', titleTranslated: 'email' },
                    { field: 'Note', titleTranslated: 'note' }
                ], gridEvents: [{
                    eType: Components.EventType.ISelectedChange, customFunction: (e) => {
                        Apex.PosManager.Controllers.ManagePosUsers.checkedChanged()
                    }
                }],
                settings: { editble: { disableEditRow: true } },
                dataSource: new kendo.data.DataSource({
                    data: [], schema:
                    {
                        model: {
                            id: 'UserId',
                            fields: {
                                isSelected: { type: 'boolean' },
                                Spend: { type: 'boolean' },
                                UserId: { type: 'number' },
                                UserName: { type: 'string' },
                                FirstName: { type: 'string' },
                                LastName: { type: 'string' },
                                CardID: { type: 'string' },
                                Email: { type: 'string' },
                                Note: { type: 'string' },
                            }
                        }
                    }
                })
            };
            return grid;
        };

        initActionsGrid = (): Components.IApxGrid => {
            var grid: Components.IApxGrid = {
                columns: [
                    {
                        field: 'isSelected', width: 85, filterable: 'boolean', titleTranslated: 'access',
                        template: '<input type="checkbox" style="margin-left: 40%;" ng-model="dataItem.isSelected" onclick="Apex.PosManager.Controllers.ManagePosUsers.checkedChanged()" />'
                    },
                    { field: 'Name', titleTranslated: 'caption' },
                    { field: 'Namee', titleTranslated: 'nameCaptionEn' }
                ],
                gridEvents: [{
                    eType: Components.EventType.ISelectedChange, customFunction: (e) => {
                        Apex.PosManager.Controllers.ManagePosUsers.checkedChanged()
                    }
                }],
                settings: { editble: { disableEditRow: true } },
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            id: 'ActionID',
                            fields: {
                                isSelected: { type: 'boolean' },
                                Name: { type: 'string' },
                                Namee: { type: 'string' },
                            }
                        }
                    }
                })
            };
            return grid;
        };

        addUser = () => {
            var data = {
                selectedUser: {},
                mode: 'add',
                formName: this.$translate.instant('Add')
            };
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("editposuser.modal.html", "editPosUserController", data, modalSize);
            dlg.result.then(result => {

                if (typeof result != undefined && result != false) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    this.toaster.pop('success', translate.instant('Attention'), translate.instant('successSaved'));
                    this.posService.GetPosUsers().then(res => {
                        var user = result;
                        if (user != undefined && user.UserId < 0)
                            user.UserId *= -1;

                        this.$scope.activeUser = user;
                        this.$scope.components.usersMainGrid.dataSource.data(res.Result);
                        _.forEach(res.Result, (item: IPosUserSelectable) => { item.isSelected = false; });

                        this.$scope.datasources.usersDatasource = res.Result;

                        _.forEach(this.$scope.components.rolesGrid.grid.dataSource.data(), (roleSelectable: IPosRoleSelectable) => {
                            roleSelectable.isSelected = false;
                        });
                        this.$scope.components.rolesGrid.grid.dataSource.sync();
                    });
                }
            });
        };

        editUser = () => {
            var data = {
                mode: 'edit',
                selectedUser: this.$scope.activeUser,
                formName: this.$translate.instant('Edit') + ': ' + this.$scope.activeUser.UserName
            };
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("editposuser.modal.html", "editPosUserController", data, modalSize);

            dlg.result.then(result => {
                if (typeof result != undefined && result != false) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    this.toaster.pop('success', translate.instant('Attention'), translate.instant('successSaved'));
                    this.posService.GetPosUsers().then(res => {
                        this.$scope.components.usersMainGrid.dataSource.data(res.Result);
                        _.forEach(res.Result, (item: IPosUserSelectable) => { item.isSelected = false; });
                        this.$scope.datasources.usersDatasource = res.Result;
                    });
                }
            });
        };


        addRole = () => {
            var data = {
                selectedRole: {},
                mode: 'add',
                formName: this.$translate.instant('Add') 
            };
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("editposrole.modal.html", "editPosRoleController", data, modalSize);
            dlg.result.then(result => {
                if (typeof result != undefined && result != false) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    this.toaster.pop('success', translate.instant('Attention'), translate.instant('successSaved'));
                    this.posService.GetPosRoles().then(res => {
                        var role = result;
                        this.$scope.activeRole = role;

                        this.$scope.components.rolesMainGrid.dataSource.data(res.Result);
                        _.forEach(res.Result, (item: IPosRoleSelectable) => { item.isSelected = false; });

                        this.$scope.datasources.rolesDatasource = res.Result;

                        _.forEach(this.$scope.components.usersGrid.grid.dataSource.data(), (userSelectable: IPosUserSelectable) => {
                            userSelectable.isSelected = false;
                        });
                        this.$scope.components.usersGrid.grid.dataSource.sync();
                    });
                }
            });
        };

        editRole = () => {
            var data = {
                mode: 'edit',
                selectedRole: this.$scope.activeRole,
                formName: this.$translate.instant('Edit') + ': ' + this.$scope.activeRole.Name
            };
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("editposrole.modal.html", "editPosRoleController", data, modalSize);

            dlg.result.then(result => {
                if (typeof result != undefined && result != false) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    this.toaster.pop('success', translate.instant('Attention'), translate.instant('successSaved'));
                    this.posService.GetPosRoles().then(res => {
                        this.$scope.components.rolesMainGrid.dataSource.data(res.Result);
                        _.forEach(res.Result, (item: IPosRoleSelectable) => { item.isSelected = false; });
                        this.$scope.datasources.rolesDatasource = res.Result;
                    });
                }
            });
        };

        changeUserPassword = () => {
            var data = {
                mode: 'changePassword',
                selectedUser: this.$scope.activeUser,
                formName: this.$translate.instant('changePassword') + ': ' + this.$scope.activeUser.UserName
            };
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("editposuser.modal.html", "editPosUserController", data, modalSize);

            dlg.result.then(result => {
                if (typeof result != undefined && result == true) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    this.toaster.pop('success', translate.instant('Attention'), translate.instant('successSaved'));
                }
            });
        };
    }

    export interface IUsersRolesEditFormScope extends angular.IScope {
        mode: string;
        formName: string;
        baseEvents: {
            save: Function;
            cancel: Function;
        };
    }

    export interface IEditUserScope extends IUsersRolesEditFormScope {
        user: PosManager.Interfaces.IPosUser;
        events: {
            resetPassword: Function;
        };
    }

    export interface IEditRoleScope extends IUsersRolesEditFormScope {
        role: PosManager.Interfaces.IPosRole;
    }

    export class EditPosUser extends Core.Controller {
        static $inject = [Globals.scope, 'data', Globals.modalInstance, Globals.translate, Globals.toaster, 'posService'];
        constructor(private $scope: IEditUserScope, data, private $uibModalInstance, private $translate, private toaster, private posService: Services.Pos) {
            super(arguments, EditPosUser.$inject);
            $scope.formName = data.formName;
            $scope.mode = data.mode;
            $scope.user = data.selectedUser;
            $scope.baseEvents = {
                cancel: () => {
                    $uibModalInstance.close(false);
                },
                save: this.save
            };
            $scope.events = {
                resetPassword: this.resetPassword
            };
        }

        resetPassword = () => {
            var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("resetPasswordConfirmation"));
            r.result.then(() => {
                this.$scope.user.Password = this.$scope.user.PasswordRepeated = '9999';
            });
        }

        save = () => {
            {
                if (this.$scope.mode == 'changePassword') {
                    if (this.$scope.user.Password == undefined || this.$scope.user.Password.trim().length == 0) {
                        this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('enterpassword'));
                        return;
                    }

                    if (this.$scope.user.PasswordRepeated == undefined || this.$scope.user.PasswordRepeated.trim().length == 0) {
                        this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('repeatpassword'));
                        return;
                    }

                    if (this.$scope.user.Password != this.$scope.user.PasswordRepeated) {
                        this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('passwordmismatch'));
                        return;
                    }

                    this.posService.ChangePosUserPassword(this.$scope.user.UserId, this.$scope.user.Password).then(res => {
                        if (res.Result == true) {
                            this.$uibModalInstance.close(true);
                        }
                    });
                }
                else {
                    if (this.$scope.mode == 'add') {
                        if (this.$scope.user.UserId == undefined || this.$scope.user.UserId.toString().trim().length == 0 ||
                            this.$scope.user.UserName == undefined || this.$scope.user.UserName.toString().trim().length == 0 ||
                            this.$scope.user.Password == undefined || this.$scope.user.Password.toString().trim().length == 0
                        ) {
                            this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('enteruseridpasswordname'));
                            return;
                        }
                        if (this.$scope.user.PasswordRepeated == undefined || this.$scope.user.PasswordRepeated.trim().length == 0) {
                            this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('repeatpassword'));
                            return;
                        }

                        if (this.$scope.user.Password != this.$scope.user.PasswordRepeated) {
                            this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('passwordmismatch'));
                            return;
                        }
                        this.$scope.user.UserId *= -1;
                    }
                    this.posService.SavePosUser(this.$scope.user).then(res => {
                        if (res.success != false) {
                            this.$uibModalInstance.close(this.$scope.user);
                        }
                        else {
                            if (this.$scope.mode == 'add')
                                this.$scope.user.UserId *= -1;
                        }
                    });
                }
            }
        }
    }

    export class EditPosRole extends Core.Controller {
        static $inject = [Globals.scope, 'data', Globals.modalInstance, 'posService', Globals.toaster, Globals.translate];
        constructor(private $scope: IEditRoleScope, data: any, private $uibModalInstance, private posService: Services.Pos, private toaster, private $translate) {
            super(arguments, EditPosRole.$inject);
            $scope.formName = data.formName;
            $scope.mode = data.mode;
            $scope.role = data.selectedRole;
            $scope.baseEvents = {
                cancel: () => {
                    $uibModalInstance.close(false);
                },
                save: this.save
            };
        }
        save = () => {
            if (this.$scope.role.Name != undefined && this.$scope.role.Name.trim().length > 0) {
                this.posService.SavePosRole(this.$scope.role).then(res => {
                    if (res.success != false && res.Result == true) {
                        this.$scope.role.RoleId = res.RoleID;
                        this.$uibModalInstance.close(this.$scope.role);
                    }
                });
            }
            else {
                this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('nameisrequired'));
            }
        }
    }
}