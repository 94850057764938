/// <reference path='../_reference.ts' />
module Apex.PosManager.Controllers {
    export interface ManagePeripheriesScope extends angular.IScope {
        components: {
            posPeripheriesGrid: Components.IApxGrid,
            scriptsLookUp: Components.IApxLookup
        },
        addMode: boolean;
        saveOrEdit: boolean;
        editedMode: boolean;
        model:
        {
            Setting: Apex.PosManager.Interfaces.IPOS_Settings
        },
        productListTypes: any[],
        AltPrice: any[],
        Service: any[],
        PaymentTypeList: any[],
        RangeCalc: any[],
        bwprctrlList: any[],
        prprodnwList: any[],
        advrtypeList: any[],
        prdblrpList: any[],
        events: {
            refresh: Function,
            getSettings: Function,
            ManPerDetails: { add: Function, save: Function, edit: Function, delete: Function, cancel: Function }
        },
        branchesByPermissions: Array<Interfaces.IBranch>,
        isDeleteFunctionalityDisabled: boolean,
        isEditDisabled: boolean
    }
    export class ManagePeripheries extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, Globals.toaster, 'commonService', 'posService'];
        constructor(private $scope: ManagePeripheriesScope, private $translate, private toaster, private commonService: Apex.Operations.Services.Common, private posService: Apex.PosManager.Services.Pos) {
            super(arguments, ManagePeripheries.$inject);
            $scope.addMode = false;
            $scope.isDeleteFunctionalityDisabled = true;
            $scope.isEditDisabled = true;
            $scope.components = {
                scriptsLookUp: this.initScriptsLookUp(),
                posPeripheriesGrid: this.initPeripheriesGrid()
            };

            $scope.model = { Setting: null };

            posService.GetScripts().then(result => {
                this.$scope.components.scriptsLookUp.dataSource.data(result.Result);
            });

            posService.GetPOSPaymentTypes().then(result => {
                $scope.PaymentTypeList = result.Result;
            });
            $scope.productListTypes = [
                { id: '0', value: 'არ იზღუდება' },
                { id: '1', value: 'ფილიალის ჭრილში არსებული' },
                { id: '2', value: 'არა ფილიალის ჭრილში არსებული' }];
            $scope.AltPrice = [
                { id: '0', value: 'არ წარმოებს' },
                { id: '1', value: '"თანხის" მიღწევისას, 2 ხაზადი არსებული' },
                { id: '2', value: 'ხელით მითითებით, 2 ხაზად' },
                { id: '3', value: '"თანხის" მიღწევისას ან ხელით, 2 ხაზად' },
                { id: '10', value: '"თანხის" მიღწევისას, 1 ხაზად' },
                { id: '20', value: 'ხელით მითითებით, 1 ხაზად' },
                { id: '30', value: '"თანხის" მიღწევისას ან ხელით, 1 ხაზად' },];
            $scope.Service = [
                { id: '0', value: 'არ წარმოებს' },
                { id: '1', value: 'ნებაყოფლობითია, გაუფრთხილებლად' },
                { id: '2', value: 'ნებაყოფლობითია, გაფრთხილებით' },
                { id: '3', value: 'აუცილებელია, იძულებით' }];
            $scope.RangeCalc = [
                { id: '0', value: 'არ წარმოებს' },
                { id: '1', value: 'წარმოებს' },
                { id: '2', value: 'წარმოებს, საქონლის საბითუმო ფასით' }];
            $scope.bwprctrlList = [
                { id: '0', value: 'აკრძალულია' },
                { id: '1', value: 'ნებადართულია' },
                { id: '2', value: 'ნებადართულია საქონლის ერთეულის ფასის რედაქტირებით' }];
            $scope.prprodnwList = [
                { id: '0', value: 'საქონლის დასახელება 50 სიმბოლომდე' },
                { id: '1', value: 'საქონლის დასახელება სიგრძის შეუკვეცავად' }];
            $scope.advrtypeList = [
                { id: '0', value: 'ამოცანის გაშვებისას' },
                { id: '1', value: 'ამოცანის გაშვებისას, ცვლის გახსნისას' },
                { id: '2', value: 'ამოცანის გაშვებისას, ცვლის გახსნისას, მომხმარებლის შეცვლისას' },
                { id: '3', value: 'ამოცანის გაშვებისას, ცვლის გახსნისას, მომხმარებლის შეცვლისას, ჩეკის დახურვისას' }];
            $scope.prdblrpList = [
                { id: '0', value: 'ჩეკის ასლი არ იბეჭდება' },
                { id: '1', value: 'უნაღდო ანგარიშსწორებით რეალიაზაციის ჩეკის ასლი' },
                { id: '2', value: 'საჩუქრის ჩეკის ასლი' },
                { id: '3', value: 'უნაღდო ანგარიშსწორებით რეალიზაციის, საჩუქრის ჩეკის ასლი' }];


            $scope.events = {
                refresh: () => {
                    $scope.components.posPeripheriesGrid.gridView.showLoading();
                    $scope.components.posPeripheriesGrid.gridView.refresh();
                },
                getSettings: (id) => {
                    posService.Getsettings(id).then((res) => {
                        $scope.model.Setting = res;
                    });
                },
                ManPerDetails: {
                    add: this.AddPosSettings,
                    save: this.SavePossSave,
                    edit: this.EditPosSettings,
                    delete: this.DeletePosSettings,
                    cancel: this.CancelMode
                }
            };
        }

        AddPosSettings = () => {
            this.$scope.saveOrEdit = true;
            this.$scope.editedMode = false;
            this.$scope.addMode = true;
            this.posService.Getsettings(-1).then((res) => {
                this.$scope.model.Setting = res;
            });
        }

        SavePossSave = () => {

            if (this.$scope.model.Setting.cashn < 0 || this.$scope.model.Setting.cashn > 99999) {
                this.toaster.pop('error', 'ყურადღება', 'სალაროს ნომრის დასაშვები მნიშვნელობებია 1-დან 99999-ის ჩათვლით!');
                return;
            }

            if (this.$scope.saveOrEdit == true) {
                var n = this.$scope.components.posPeripheriesGrid.dataSource.data();
                if (_.findWhere(n, { cashn: parseFloat(this.$scope.model.Setting.cashn.toString()) })) {
                    this.toaster.pop('error', 'ყურადღება', 'სალარო ამ ნომრით უკვე არსებობს');
                    return;
                }
                this.$scope.addMode = false;
                this.posService.SavePosSettings(this.$scope.model.Setting).then((res) => {
                    if (res.Result == true) {
                        this.toaster.pop('success', 'ყურადღება', 'წარმატებით დაემატა');
                        this.$scope.events.refresh();
                    }
                });
            }
            else {
                this.$scope.addMode = false;
                this.$scope.editedMode = false;
                this.posService.SavePosSettings(this.$scope.model.Setting).then((res) => {
                    if (res.Result == true) {
                        this.toaster.pop('success', 'ყურადღება', 'რედაქტირება წარმატებით დასრულდა');
                        this.$scope.events.refresh();
                    }
                });
            }
        }
        EditPosSettings = () => {
            this.$scope.editedMode = true;
            this.$scope.saveOrEdit = false;
            this.$scope.addMode = true;
        }
        DeletePosSettings = () => {
            var confirmationDialog = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            confirmationDialog.result.then(() => {
                this.posService.DeletePosSetting(this.$scope.model.Setting.cashn).then((result: any) => {
                    Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('success', 'ყურადღება', 'წარმატებით წაიშალა') });
                    this.$scope.events.refresh();
                });
            })
        };
        CancelMode = () => {
            this.$scope.editedMode = false;
            this.$scope.addMode = false;
        }

        PosPerGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "is_claimed", width: 30, template:
                `<div class="text-center"  ng-if="dataItem.is_claimed"><i class="fa fa-circle text-danger" style="font-size: 8px;"></i></div>
                <div class="text-center"  ng-if="!dataItem.is_claimed"><i class="fa fa-circle text-success" style="font-size: 8px;"></i></div>` },
            { field: "cashnString()", width: 80, titleTranslated: "salaryNum", format: Core.Kendo.Format.Number, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.cashn ? data.cashn.count : 0) #' },
        ];
        initScriptsLookUp = (): Components.IApxLookup => {
            var lookUp: Components.IApxLookup = {
                columns: [
                    { field: 'Name', titleTranslated: 'nameCaption' },
                    { field: 'Description', titleTranslated: 'Description' }
                ],
                dataSource: [].toDatasource(),
                valueText: 'Name'
            };

            return lookUp;
        }

        initPeripheriesGrid = () => {
            var dataSource = new kendo.data.DataSource({
                aggregate: [{ field: "cashn", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var fn: any = this.posService.LoadPos();
                        fn.then(result => {
                            var x = _.reject(result, function (itm: Apex.PosManager.Interfaces.IPOS_Settings) { return itm.cashn == -1 });
                            options.success(x);
                        });
                    }
                },
                schema: {
                    model: {
                        id: 'cashn', 
                        cashnString: function (item) {
                            return this.cashn.toString();
                        }
                    }
                },
            });

            var grid: Components.IApxGrid =
                {
                    gridEvents: [{
                        eType: Components.EventType.Change, eFunction: () => {
                            var selectedRow: any = grid.grid.dataItem(grid.grid.select());
                            if (selectedRow != null) {
                                let item = selectedRow.get("cashn");
                                this.$scope.events.getSettings(item);
                            }
                            else {
                                delete this.$scope.model.Setting;
                            }

                            if (selectedRow != null && selectedRow != undefined && !selectedRow.is_claimed) {
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.isDeleteFunctionalityDisabled = false);
                            }
                            else { Core.Utils.Helper.safeApply(this.$scope, this.$scope.isDeleteFunctionalityDisabled = true); }

                            if (selectedRow != null && selectedRow != undefined) {
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.isEditDisabled = false);
                            }
                            else { Core.Utils.Helper.safeApply(this.$scope, this.$scope.isEditDisabled = true); }
                        }
                    }],
                    dataSource: dataSource,
                    columns: this.PosPerGridColumns,
                    settings: { editble: { disableEditRow: true } }
                };
            return grid;
        }
    }
} 
