///<reference path="./../_reference.ts"/>
module Apex.PosManager.Interfaces {
    export interface IPosUser {
        UserId?: number;
        UserName?: string;
        Spend?: boolean;
        FirstName?: string;
        LastName?: string;
        CardID?: string;
        Email?: string;
        Note?: string;
        Password?: string;
        PasswordRepeated?: string;
    }
}