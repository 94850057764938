/// <reference path='../_reference.ts' />
module Apex.PosManager.Services {
    'use strict'
    export class Pos extends Core.Service {
        GetBookPosByDateInterval(fromDate: any, toDate: any): angular.IPromise<any> {
            var params = {
                nm: 'd1={0}&d2={1}',
                pr: [fromDate, toDate]
            }
            return this.httpGet("/POS/CheckBookPos/ByDateInterval", params);
        }

        GetBookPosByDate(date: any): angular.IPromise<any> {
            var params = {
                nm: 'd={0}',
                pr: [date]
            }
            return this.httpGet("/POS/CheckBookPos/ByDate", params);
        }
        LoadPos(): angular.IPromise<any> {
            return this.httpGet("/POS/Settingss");
        }
        Getsettings(id: Number): angular.IPromise<any> {
            return this.httpGet("/POS/Settings/" + id + "?jsconfig=tsh:sf");
        }
        GetPOSPaymentTypes(): angular.IPromise<any> {
            return this.httpGet("/POS/PaymentTypes/");
        }
        SavePosSettings(PosSettings: Interfaces.IPOS_Settings): angular.IPromise<any> {
            return this.httpPut("/POS/SaveSettings", { PosSettings: PosSettings });
        }
        DeletePosSetting(cashN: Number): angular.IPromise<any> {
            return this.httpDelete("/POS/DeleteSettings/" + cashN);
        }


        BookPosOperations(branchId: any, date: any): angular.IPromise<any> {
            return this.httpPost("/POS/Operations/Book", { BranchID: branchId, OpDate: date }, ["412", "409", "400"]);
        }

        UnBookPosOperations(branchId: any, date: any): angular.IPromise<any> {
            return this.httpPost("/POS/Operations/UnBook", { BranchId: branchId, OpDate: date });
        }

        GetScripts(): angular.IPromise<any> {
            return this.httpGet("/POS/Scripts");
        }

        GetPosUsers(): angular.IPromise<any> {
            return this.httpGet("/POS/UM/Users");
        }

        GetPosRoles(): angular.IPromise<any> {
            return this.httpGet("/POS/UM/Roles");
        }

        GetPosUserRolesByUser(userID: number = null): angular.IPromise<any> {
            return this.httpGet("/POS/UM/UserRoles?UserID=" + userID);
        }
        GetPosUserRolesByRole(roleID: string = null): angular.IPromise<any> {
            return this.httpGet("/POS/UM/UserRoles?RoleID=" + roleID);
        }

        GetPosRoleActions(roleId: any): angular.IPromise<any> {
            return this.httpGet("/POS/UM/Roles/" + roleId + "/ActionsView");
        }

        GetPosActions(): angular.IPromise<any> {
            return this.httpGet("/POS/UM/Actions");
        }


        SavePosActions(roleId: string, actionsIdList: Array<string>): angular.IPromise<any> {
            var params = {
                Actions: actionsIdList
            };
            return this.httpPut("/POS/UM/Roles/" + roleId + "/Actions", params);
        }

        SavePosUserRoles(userId: number, roleId: string, roleIdList: Array<string>, userIdList: Array<number>): angular.IPromise<any> {
            var params = {
                UserID: userId,
                RoleID: roleId,
                Roles: roleIdList,
                Users: userIdList
            };
            return this.httpPut("/POS/UM/UserRoles/", params);
        }

        SavePosUser(user: Interfaces.IPosUser): angular.IPromise<any> {
            return this.httpPut("/POS/UM/Users/", user);
        }

        SavePosRole(role: Interfaces.IPosRole): angular.IPromise<any> {
            var params = {
                Role: role
            };
            return this.httpPut("/POS/UM/Roles/", params);
        }

        DeletePosUser(userId: number): angular.IPromise<any> {
            return this.httpDelete("/POS/UM/Users/" + userId);
        }

        DeletePosRole(roleId: string): angular.IPromise<any> {
            return this.httpDelete("/POS/UM/Roles/" + roleId);
        }

        ChangePosUserPassword(userId: number, newPassword: string): angular.IPromise<any> {
            var params = {
                UserID: userId,
                NewPassword: newPassword
            };
            return this.httpPatch("/POS/UM/ChangePassword1", params);
        }
    } 
}