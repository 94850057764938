///<reference path="./../_reference.ts"/>
module Apex.PosManager.Interfaces {
    export interface IBookPosByDateInterval {
        OpDate: Date,
        VgSale: number,
        VgSaleZ: number,
        BVgSale: number,
        VgReturn: number,
        VgReturnZ: number,
        BVgReturn:number
    }
}