/// <reference path='./_reference.ts' />
module Apex.PosManager {
    'use strict';
    var apxPosManager = new Apex.Core.Module('posmanager', ['apex.webUI.components'], PosManager);
    var routes: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'posmanager',
                url: '^/posmanager',
                template: '<ui-view/>',
                navigation: { caption: 'posmanager', navigationClass: 'md md-dvr' },
                childRoutes: [
                    {
                        state: 'peripheries',
                        url: '/peripheries',
                        controller: Apex.PosManager.Controllers.ManagePeripheries.getName(),
                        templateUrl: "manage.peripheries.html",
                        navigation: {
                            caption: 'manageperipheries'
                        }
                    },
                    {
                        controller: Apex.PosManager.Controllers.ManagePosUsers.getName(),
                        state: 'posusers',
                        url: '/posusers',
                        templateUrl: 'manage.pos.users.html',
                        navigation: { caption:'managepermissions' }
                    },
                    {
                        state: 'bookRecipes',
                        url: '/bookRecipes',
                        controller: Apex.PosManager.Controllers.BookRecipes.getName(),
                        templateUrl: "book.recipes.html",
                        navigation: {
                            caption: 'posbook'
                        }
                    }
                ]
            }
        ];

    apxPosManager.regRoutes(routes, 'apex');
}