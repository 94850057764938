///<reference path="./../_reference.ts"/>
module Apex.PosManager.Interfaces {

    export interface IPOS_Settings {
        cashn?: number;

        orgname?: string;

        orgid?: string; 

        orgaddress?: string; 

        fastfood?: boolean; 

        ofteng?: boolean; 

        combosale?: boolean; 

        combosalex?: boolean; 

        wholesale?: boolean;

        whsauton?: boolean;

        altprice?: number;

        altinpr?: number;

        altpriceboundary?: number;

        service?: number;

        serviceprice?: number;

        cardact?: string;

        cardacton?: boolean;

        cardps?: string;

        autoquit?: string;

        toarchin?: number;

        printGrouped?: boolean;

        saleWithSPriceg?: boolean;

        linectrl?: number;

        round_to?: number;

        goodssuminpoints?: number;

        bwinmonth?: boolean;

        bwprctrl?: number;

        orgdmd?: string;

        orgrest?: string;

        devadv?: string;

        advert?: boolean;

        advdelay?: number;

        prprodnw?: number;

        logosm?: boolean;

        urlleft?: string;

        urlright?: string;

        urlbottom?: string;

        advrtype?: number;

        prdblrp?: number;

        prfull?: boolean;

        prrmnd?: boolean;

        prlogo?: boolean;

        is_claimed?: boolean;

        VoucherPaymentType?: number;

        AllowOffline?: boolean;

        LimetedCardPaymentType?: number;

        defPaymentType?: number;

        saleCheckPrefix?: string;

        saleCheckSuffix?: string;

        PrintCompactZ?: boolean;

        RestrictedPaymentTypes?: number[];

        GoodsByBranch?: number;

        autosavein?: number;

        pharmasale?: boolean;

        Script?: IPOS_Script;

        BankName?: string;

        BankCode?: string;

        BankAccount?: string;

        BranchId?: number;

        pointsProportionalToAmounth?: boolean;

        RangeCalc?: number;

        coupon?: boolean;

        AllowSkipF?: boolean;
    }

    export interface IPOS_Script {

        Id?: string;

        Name?: string;

        Description?: string;

        VersionCode?: number;

        Body?: string;

        Spend?: boolean;
    }
}