/// <reference path='../_reference.ts' />
module Apex.PosManager.Controllers {
    export interface BookRecipesScope extends angular.IScope {
        stop: boolean,
        bookUnBookDisabled: boolean,
        showRefreshInfo: boolean,
        filters: { BookDateBetween: any, DateBetween: any, selectedBranch: any[] },
        components: {
            branchesMultiselect: {
                datasource?: PosManager.Interfaces.IBranch[],
                settings: {
                    buttonClasses: string,
                    scrollable: boolean,
                    scrollableHeight: string,
                    enableSearch: any,
                    idProp: string,
                    displayProp: string
                },
                translation: {
                    checkAll: string,
                    uncheckAll: string,
                    searchPlaceholder: string,
                    dynamicButtonTextSuffix: string,
                    buttonDefaultText: string
                }
            },
            posByDateIntervalGrid: Apex.Components.IApxGrid,
            posByDateGrid: Apex.Components.IApxGrid,
            bookResultGrid: Apex.Components.IApxGrid
        },
        events: { refresh: Function, checkIn: Function, checkOut: Function, stopBook: Function },
        dateBetweenText: string;
    }
    export class BookRecipes extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, 'commonService', 'posService','$timeout'];
        constructor(private $scope: BookRecipesScope, private toaster: any, commonService: Apex.Operations.Services.Common, private posService: Apex.PosManager.Services.Pos, private $timeout) {
            super(arguments, BookRecipes.$inject);
            var dateNow = new Date();
            $scope.stop = true;
            $scope.bookUnBookDisabled = false;
            $scope.filters = {
                BookDateBetween:
                {
                    fromDate: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0), 
                    toDate: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0)
                },
                DateBetween: {},
                selectedBranch: []
            };

            $scope.components = {
                branchesMultiselect: {
                    datasource: [],
                    settings: {
                        buttonClasses: "btn btn-info",
                        scrollable: true,
                        scrollableHeight: "400px",
                        enableSearch: true,
                        idProp: 'BranchID',
                        displayProp: 'FullName'
                    },
                    translation: {
                        checkAll: 'მონიშვნა',
                        uncheckAll: 'მოხსნა',
                        searchPlaceholder: 'ძებნა...',
                        dynamicButtonTextSuffix: 'მონიშნული',
                        buttonDefaultText: 'აირჩიეთ ფილიალი'
                    }
                },
                posByDateGrid: this.initPosByDateGrid(),
                posByDateIntervalGrid: this.initPosByDateIntervalGrid(),
                bookResultGrid: this.initBookResultGrid()
            }

            $timeout(() => {
                $('.axp-date-beetwen').width(97);
            });
            
            $scope.$watch('[filters.DateBetween.fromDate,filters.DateBetween.toDate]', (date) => {
                $scope.showRefreshInfo = true;
            });

            $scope.$watch('[filters.BookDateBetween.fromDate,filters.BookDateBetween.toDate]', (date: any) => {
                if (date != undefined) {
                    var oneDay = 24 * 60 * 60 * 1000;
                    var days = Math.round(Math.abs((date[0].getTime() - date[1].getTime()) / (oneDay))) + 1;
                    $scope.dateBetweenText = 'შეიცვლება ' + days + ' დღე!';
                }
            });

            $scope.$watch('components.posByDateIntervalGrid.grid', (grid: kendo.ui.Grid) => {
                if (grid != undefined) {
                    grid.tbody.bind('dblclick', function (e) {
                        var selectedObject: any = grid.dataItem(grid.select());
                        if (selectedObject != undefined && selectedObject != null) {
                            $scope.components.posByDateGrid.gridView.showLoading();
                            posService.GetBookPosByDate(selectedObject.OpDate).then(result => {                             
                                $scope.components.posByDateGrid.dataSource.data(result.Result);
                                $scope.components.posByDateGrid.gridView.hideLoading();
                            });
                        }
                    });
                }
            });

            $scope.events = {
                refresh: () => {
                    $scope.components.posByDateIntervalGrid.gridView.showLoading();
                    posService.GetBookPosByDateInterval(Apex.Core.Utils.Helper.toLocalTimeISO($scope.filters.DateBetween.fromDate), Apex.Core.Utils.Helper.toLocalTimeISO($scope.filters.DateBetween.toDate)).then(result => {
                        $scope.showRefreshInfo = false;
                        $scope.components.posByDateIntervalGrid.dataSource.data(result.Result);
                        $scope.components.posByDateGrid.dataSource.data([]);
                        $scope.components.posByDateIntervalGrid.gridView.hideLoading();
                    });
                },
                checkIn: this.checkIn,
                checkOut: this.checkOut,
                stopBook: this.stopBook
            }
            commonService.getBranchesByPermissions().then(res => {
                _.forEach(res.Result, (item: Apex.PosManager.Interfaces.IBranch) => {
                    item.FullName = item.BranchID + '. ' + item.Name;
                });
                $scope.components.branchesMultiselect.datasource = res.Result;
            });


        }

        getDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "BVgReturn", aggregate: "sum" },
                    { field: "VgReturn", aggregate: "sum" },
                    { field: "VgReturnZ", aggregate: "sum" },
                    { field: "BVgSale", aggregate: "sum" },
                    { field: "VgSale", aggregate: "sum" },
                    { field: "VgSaleZ", aggregate: "sum" },
                    { field: "OpDate", aggregate: "count" },
                    { field: "BranchName", aggregate: "count" }
                ],
                schema: {
                    model: {
                        getBranchName: function () {
                            return this.BranchName != undefined ? this.BranchName : "--------";
                        },
                        getVgSale: function (item) {
                            return Number(this.VgSale.toFixed(2));
                        },
                        getBVgSale: function (item) {
                            return Number(this.BVgSale.toFixed(2));
                        },
                        getBVgReturn: function (item) {
                            return Number(this.BVgReturn.toFixed(2));
                        },
                        getVgReturn: function (item) {
                            return Number(this.VgReturn.toFixed(2));
                        },
                        getVgReturnZ: function (item) {
                            return Number(this.VgReturnZ.toFixed(2));
                        },
                        getVgSaleZ: function (item) {
                            return Number(this.VgSaleZ.toFixed(2));
                        },
                        VgSaleZDiff: function (item) {
                            return Number((this.VgSale - this.VgSaleZ).toFixed(2));
                        },
                        BDiff: function (item) {
                            return Number((this.VgSale - this.BVgSale).toFixed(2));
                        },
                        VgReturnZDiff: function (item) {
                            return Number((this.VgReturn - this.VgReturnZ).toFixed(2));
                        },
                        BReturnDiff: function (item) {
                            return Number((this.VgReturn - this.BVgReturn).toFixed(2));
                        },
                        fields: {
                            'getVgSale()': { type: 'number' },
                            'getBVgSale()': { type: "number" },
                            'getBVgReturn()': { type: "number" },
                            'getVgReturn()': { type: "number" },
                            'getVgReturnZ()': { type: "number" },
                            'getVgSaleZ()': { type: "number" },
                            'BDiff()': { type: "number" },
                            'BReturnDiff()': { type: "number" },
                            'VgReturnZDiff()': { type: "number" },
                            'VgSaleZDiff()' : { type: "number" }
                        }
                    }
                }
            });
        }

        initPosByDateIntervalGrid = () => {
            var grid: Components.IApxGrid =
                {
                    dataSource: this.getDatasource(),
                    columns: this.PosByDateIntervalGridColumns,
                    settings: { editble: { disableEditRow: true }, height: 450 }
                };

            return grid;
        }

        initBookResultGrid = () => {
            var grid: Components.IApxGrid =
                {
                    dataSource: new kendo.data.DataSource({ data: [], aggregate: [{ field: "text", aggregate: "count" }] }),
                    columns: [
                        {
                            field: 'text', width: 600, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.text ? data.text.count : 0) #',
                            template: '<span style="#= error ? "color: \\#FF0000;" : "" #">#: text #</span>'
                        }
                    ],
                    settings: { height: 588, editble: { disableEditRow: true } }
                };

            return grid;
        }

        checkIn = () => {
            if (this.$scope.filters.selectedBranch == undefined || this.$scope.filters.selectedBranch == null || this.$scope.filters.selectedBranch.length == 0) {
                this.toaster.pop('error', 'ყურადღება', 'გთხოვთ აირჩიოთ ფილიალი.');
                return;
            }
            var r = Apex.Core.Utils.ModalForm.openConfirmation("ნამდვილად გსურთ გაგრძელება?");
            r.result.then(() => {
                this.$scope.stop = false;
                this.$scope.components.bookResultGrid.dataSource.data([]);
                var fromDate = new Date(this.$scope.filters.BookDateBetween.fromDate);
                this.$scope.bookUnBookDisabled = true;
                this.$scope.components.posByDateIntervalGrid.gridView.showLoading();
                this.makeBookOperation(this.$scope.filters.selectedBranch, fromDate);
            });
        };

        makeBookOperation(branchIdList: Array<any>, fromDate: Date) {
            if (!this.$scope.stop) {
                var branchId = _.first(branchIdList);
                if (branchId != undefined) {
                    branchIdList = _.without(branchIdList, branchId);
                    this.posService.BookPosOperations(branchId.id, Apex.Core.Utils.Helper.toLocalTimeISO(fromDate)).then(result => {
                        if (result.Result == true) {
                            this.$scope.components.bookResultGrid.dataSource.data().push({ error: false, text: kendo.toString(fromDate, "dd/MM/yyyy") + ',' + 'ფილიალი ' + branchId.id + ' ჩაიხურა' });
                        }
                        if ((result.success != false && branchIdList.length > 0) || (result.statusCode == 412)) {
                            this.makeBookOperation(branchIdList, fromDate);
                        }

                        if (result.success == false && result.statusCode != 400 && result.statusCode != 409 && result.statusCode != 412) {
                            this.$scope.bookUnBookDisabled = false;
                            this.$scope.components.posByDateIntervalGrid.gridView.hideLoading();
                            return;
                        }

                        if (result.success == false && (result.statusCode == 412 || result.statusCode == 409 || result.statusCode == 400)) {
                            if (result.response.data && result.response.data.ResponseStatus && result.response.data.ResponseStatus.Message) {
                                this.$scope.components.bookResultGrid.dataSource.data().push({ error: true, text: kendo.toString(fromDate, "dd/MM/yyyy") + ',' + 'ფილიალი ' + branchId.id + '. ' + result.response.data.ResponseStatus.Message });
                            }
                        }

                        if (branchIdList.length == 0) {
                            var toDate = new Date(this.$scope.filters.BookDateBetween.toDate);
                            fromDate.setDate(fromDate.getDate() + 1);
                            if (fromDate <= toDate) {
                                this.makeBookOperation(this.$scope.filters.selectedBranch, fromDate);
                            }
                            else {
                                this.$scope.bookUnBookDisabled = false;
                                this.$scope.stop = true;
                                this.$scope.components.posByDateIntervalGrid.gridView.hideLoading();
                            }
                        }
                    });
                }
            }
        };

        makeUnBookOperation(branchIdList: Array<any>, toDate: Date) {
            if (!this.$scope.stop) {
                var branchId = _.first(branchIdList);
                if (branchId != undefined) {
                    branchIdList = _.without(branchIdList, branchId);
                    this.posService.UnBookPosOperations(branchId.id, Apex.Core.Utils.Helper.toLocalTimeISO(toDate)).then(result => {
                        if (result.Result == true) {
                            this.$scope.components.bookResultGrid.dataSource.data().push({ error: false, text: kendo.toString(toDate, "dd/MM/yyyy") + ',' + ' ფილიალი ' + branchId.id + ' ამოიხურა' });
                        }
                        if ((result.success != false && branchIdList.length > 0) || (result.statusCode === 412)) {
                            this.makeUnBookOperation(branchIdList, toDate);
                        }

                        if (result.success == false && result.statusCode != 400 && result.statusCode != 409 && result.statusCode != 412) {
                            this.$scope.bookUnBookDisabled = false;
                            this.$scope.components.posByDateIntervalGrid.gridView.hideLoading();
                            return;
                        }

                        if (result.success == false && (result.statusCode == 412 || result.statusCode == 409 || result.statusCode == 400)) {
                            if (result.response.data && result.response.data.ResponseStatus && result.response.data.ResponseStatus.Message) {
                                this.$scope.components.bookResultGrid.dataSource.data().push({ error: true, text: kendo.toString(fromDate, "dd/MM/yyyy") + ',' + 'ფილიალი ' + branchId.id + '. ' + result.response.data.ResponseStatus.Message });
                            }
                        }
                        if (branchIdList.length == 0) {
                            var fromDate = new Date(this.$scope.filters.BookDateBetween.fromDate);
                            toDate.setDate(toDate.getDate() - 1);
                            if (fromDate <= toDate) {
                                this.makeUnBookOperation(this.$scope.filters.selectedBranch, toDate);
                            }
                            else {
                                this.$scope.bookUnBookDisabled = false;
                                this.$scope.stop = true;
                                this.$scope.components.posByDateIntervalGrid.gridView.hideLoading();
                            }
                        }
                    });
                }
            }
        };

        checkOut = () => {
            if (this.$scope.filters.selectedBranch == undefined || this.$scope.filters.selectedBranch == null || this.$scope.filters.selectedBranch.length == 0) {
                this.toaster.pop('error', 'ყურადღება', 'გთხოვთ აირჩიოთ ფილიალი.');
                return;
            }

            var r = Apex.Core.Utils.ModalForm.openConfirmation("ნამდვილად გსურთ გაგრძელება?");
            r.result.then(() => {
                this.$scope.stop = false;
                this.$scope.components.bookResultGrid.dataSource.data([]);
                var toDate = new Date(this.$scope.filters.BookDateBetween.toDate);
                this.$scope.bookUnBookDisabled = true;
                this.$scope.components.posByDateIntervalGrid.gridView.showLoading();
                this.makeUnBookOperation(this.$scope.filters.selectedBranch, toDate);
            });
        };
        stopBook = () => {
            this.$scope.stop = true;
            this.$scope.bookUnBookDisabled = false;
            this.$scope.components.posByDateIntervalGrid.gridView.hideLoading();
        }

        PosByDateIntervalGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "OpDate", width: 100, filterable: false, titleTranslated: "OperationDate", format: Core.Kendo.Format.Date,
                footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.OpDate ? data.OpDate.count : 0) #',
                template: '<span style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#= kendo.toString(new Date(parseInt(OpDate.replace("/Date(",""))),"dd/MM/yyyy") #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSale ? data.VgSale.sum.toFixed(2) : 0) #',
                field: "getVgSale()", width: 100, titleTranslated: "vgsale", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgSale==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgSale.toFixed(2)  #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSaleZ ? data.VgSaleZ.sum.toFixed(2) : 0) #',
                field: "getVgSaleZ()", width: 100, titleTranslated: "vgsalez", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgSaleZ==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgSaleZ.toFixed(2) #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString( (data.VgSale && data.VgSaleZ) ? (data.VgSale.sum - data.VgSaleZ.sum).toFixed(2) :0) #',
                field: "VgSaleZDiff()", width: 100, titleTranslated: "vgsalezdiff", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgSaleZDiff()==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgSaleZDiff() #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSaleZ ? data.BVgSale.sum.toFixed(2) : 0) #',
                field: "getBVgSale()", width: 100, titleTranslated: "bvgsale", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=BVgSale==0#" style="#= ( Math.abs((BVgSale - VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: BVgSale.toFixed(2) #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString( (data.VgSale && data.VgSaleZ) ? (data.VgSale.sum - data.BVgSale.sum).toFixed(2) :0) #',
                field: "BDiff()", width: 100, titleTranslated: "bdiff", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=BDiff()==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: BDiff() #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSale ? data.VgReturn.sum.toFixed(2) : 0) #',
                field: "getVgReturn()", width: 100, titleTranslated: "vgreturn", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgReturn==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgReturn.toFixed(2) #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSale ? data.VgReturnZ.sum.toFixed(2) : 0) #',
                field: "getVgReturnZ()", width: 100, titleTranslated: "vgreturnz", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgReturnZ==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgReturnZ.toFixed(2) #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString( (data.VgSale && data.VgSaleZ) ? (data.VgReturn.sum - data.VgReturnZ.sum).toFixed(2) :0) #',
                field: "VgReturnZDiff()", width: 100, titleTranslated: "vgreturnzdiff", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgReturnZDiff()==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgReturnZDiff() #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSale ? data.BVgReturn.sum.toFixed(2) : 0) #',
                field: "getBVgReturn()", width: 100, titleTranslated: "bvgreturn", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=BVgReturn==0#" style="#= (Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: BVgReturn.toFixed(2) #</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString( (data.VgSale && data.VgSaleZ) ? (data.VgReturn.sum - data.BVgReturn.sum).toFixed(2) :0) #',
                field: "BReturnDiff()", width: 100, titleTranslated: "breturndiff", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=BReturnDiff()==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: BReturnDiff() #</span>'
            }
        ];

        PosByDateGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "getBranchName()", width: 200,
                footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.BranchName ? data.BranchName.count : 0) #',
                titleTranslated: "branch", template: '<span style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: getBranchName()#</span>'
            },
            {
                field: "getVgSale()", width: 100,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSale ? data.VgSale.sum.toFixed(2) : 0) #',
                titleTranslated: "vgsale", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgSale==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgSale.toFixed(2)#</span>'
            },
            {
                field: "getVgSaleZ()",
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSaleZ ? data.VgSaleZ.sum.toFixed(2) : 0) #',
                width: 100, titleTranslated: "vgsalez", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgSaleZ==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgSaleZ.toFixed(2)#</span>'
            },
            {
                field: "VgSaleZDiff()",
                footerTemplate: '<span translate=sum> </span> #= kendo.toString( (data.VgSale && data.VgSaleZ) ? (data.VgSale.sum - data.VgSaleZ.sum).toFixed(2) :0) #',
                width: 100, titleTranslated: "vgsalezdiff", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgSaleZDiff()==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgSaleZDiff() #</span>'
            },
            {
                field: "getBVgSale()",
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSaleZ ? data.BVgSale.sum.toFixed(2) : 0) #',
                width: 100, titleTranslated: "bvgsale", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=BVgSale==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: BVgSale.toFixed(2)#</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString( (data.VgSale && data.VgSaleZ) ? (data.VgSale.sum - data.BVgSale.sum).toFixed(2) :0) #',
                field: "BDiff()", width: 100, titleTranslated: "bdiff", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=BDiff()==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: BDiff() #</span>'
            },
            {
                field: "getVgReturn()", width: 100,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSale ? data.VgReturn.sum.toFixed(2) : 0) #',
                titleTranslated: "vgreturn", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgReturn==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgReturn.toFixed(2)#</span>'
            },
            {
                field: "getVgReturnZ()",
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSale ? data.VgReturnZ.sum.toFixed(2) : 0) #',
                width: 100, titleTranslated: "vgreturnz", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgReturnZ==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgReturnZ.toFixed(2)#</span>'
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString( (data.VgSale && data.VgSaleZ) ? (data.VgReturn.sum - data.VgReturnZ.sum).toFixed(2) :0) #',
                field: "VgReturnZDiff()", width: 100, titleTranslated: "vgreturnzdiff", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=VgReturnZDiff()==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: VgReturnZDiff() #</span>'
            },
            {
                field: "getBVgReturn()",
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgSale ? data.BVgReturn.sum.toFixed(2) : 0) #',
                width: 100, titleTranslated: "bvgreturn", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=BVgReturn==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: BVgReturn.toFixed(2)#</span>'
            },
            {
                field: "BranchId", hidden: true
            },
            {
                footerTemplate: '<span translate=sum> </span> #= kendo.toString( (data.VgSale && data.VgSaleZ) ? (data.VgReturn.sum - data.BVgReturn.sum).toFixed(2) :0) #',
                field: "BReturnDiff()", width: 100, titleTranslated: "breturndiff", format: Core.Kendo.Format.Price4,
                template: '<span ng-hide="#=BReturnDiff()==0#" style="#= ( Math.abs((BVgSale -VgSale))>1 || Math.abs((VgSaleZ -VgSale))>1 || Math.abs((VgReturn - VgReturnZ))>0.1 || Math.abs((BVgReturn -VgReturn))>0.1 ) ? "color: \\#FF0000;" : "" #">#: BReturnDiff() #</span>'
            }
        ];

        initPosByDateGrid = () => {
            var grid: Components.IApxGrid =
                {
                    dataSource: this.getDatasource(),
                    columns: this.PosByDateGridColumns,
                    settings: { editble: { disableEditRow: true }, height: 380 }
                };
            return grid;
        }
    }
}
